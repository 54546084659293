import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import InstagramIcon from "../svg/InstagramIcon"
import PinterestIcon from "../svg/PinterestIcon"
import TwitterIcon from "../svg/TwitterIcon"

import logo from "../images/lvxp.png"

const Header = ({ siteTitle }) => (
  <header>
    <div className="d-flex justify-content-between align-items-center">
      <div>
        <img src={logo} alt="lvxp designs logo" height="80" width="80" />
      </div>
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            textDecoration: `none`,
          }}
        >
          Designs by LVXP
        </Link>
      </h1>
      <div>
        <a title="Instagram" href="https://www.instagram.com/lvxpdesigns/"><InstagramIcon /></a>
        <a title="Pinterest" href="https://www.pinterest.com.au/60deaef154c2c847d03214e65c81e0/"><PinterestIcon /></a>
        <a title="Twitter" href="https://twitter.com/lvxpdesigns"><TwitterIcon /></a>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
